<template>
   <div class="role">
      <div ref="search" class="search">
         <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
            <el-form :inline="true" :model="searchForm" label-width="80px">
               <el-form-item label="角色名称">
                  <el-input v-model="searchForm.sysRiName" :size="$store.state.size" placeholder="角色名称" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="角色状态">
                  <el-select v-model="searchForm.sysRiStatus" :size="$store.state.size" placeholder="角色状态" clearable>
                     <el-option label="启用" value="0"></el-option>
                     <el-option label="停用" value="1"></el-option>
                  </el-select>
               </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-button v-if="hasPermission('2-2-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true)" icon="el-icon-search">查询
            </el-button>
            <el-button v-if="hasPermission('2-2-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空
            </el-button>
            <el-button v-if="hasPermission('2-2-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增
            </el-button>
         </el-card>
      </div>
      <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" border @sort-change="sortChange" :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
         <el-table-column prop="sysRiName" align="center" label="角色名称">
         </el-table-column>
         <el-table-column align="center" label="角色状态">
            <template slot-scope="scope">
               <el-switch v-model="scope.row.sysRiStatus" @change="updateStatus(scope.row)" v-if="scope.row.sysRiId!='1'" :active-value="0" :inactive-value="1" active-text="启用" inactive-text="停用">
               </el-switch>
               <div v-if="scope.row.sysRiId=='1'"></div>
            </template>
         </el-table-column>
         <el-table-column align="center" label="操作">
            <template slot-scope="scope">
               <el-button v-if="scope.row.sysRiId!='1'&&hasPermission('2-2-d')" type="text" size="small" @click="grantOpen(scope.row)" icon="el-icon-setting">权限
               </el-button>
               <el-button v-if="scope.row.sysRiId!='1'&&hasPermission('2-2-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑
               </el-button>
               <el-button v-if="scope.row.sysRiId!='1'&&hasPermission('2-2-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除
               </el-button>
               <div v-if="scope.row.sysRiId=='1'"></div>
            </template>
         </el-table-column>
      </el-table>
      <pagination :page-size='currentPageSize' :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>

      <!--    保存模态框-->
      <assembly-dialog :title="dialogTitle" :visible.sync="dialogVisibleSave" width="30%" @before-close="handleClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="130px">
            <el-form-item label="角色名称" prop="sysRiName">
               <el-input v-model="saveForm.sysRiName" :size="$store.state.size" placeholder="角色名称" clearable></el-input>
            </el-form-item>

            <el-form-item v-if="dialogTitle == '新增'" label="是否制造商角色" prop="getSysTiType">
               <el-select v-model="saveForm.getSysTiType" :size="$store.state.size" placeholder="角色类型" clearable>
                  <el-option label="是" :value="2"></el-option>
                  <el-option label="否" :value="1"></el-option>
               </el-select>
            </el-form-item>

            <el-form-item label="角色状态" prop="sysRiStatus">
               <el-select v-model="saveForm.sysRiStatus" :size="$store.state.size" placeholder="角色状态" clearable>
                  <el-option label="启用" :value="0"></el-option>
                  <el-option label="停用" :value="1"></el-option>
               </el-select>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
         </span>
      </assembly-dialog>
      <!--    权限模态框-->
      <assembly-dialog title="权限" :visible.sync="dialogVisibleGrant" top="5vh" width="30%" @before-close="grantClose">
         <!-- <el-input
          style="margin-bottom: 10px"
          placeholder="输入关键字进行过滤"
          v-model="filterText">
      </el-input> -->
         <div style="height: 600px;overflow-y: auto; padding: 10px">
            <el-tree ref="tree" :data="treeData" :props="defaultProps" node-key="sysMiId" show-checkbox default-expand-all :filter-node-method="filterNode"></el-tree>
         </div>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="grantClose">关 闭</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveGrant">保 存</el-button>
         </span>
      </assembly-dialog>
   </div>
</template>

<script>
import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "components/assembly-dialog";

export default {
   name: "role",
   components: {
      AssemblyDialog,
      pagination
   },
   watch: {
      //监听头部展开收起变化
      '$store.state.isHeader'() {
         this.setTableHeight()
      },
      //监听快速导航展开收起变化
      '$store.state.fastNav'() {
         this.setTableHeight()
      },
      //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight()
      },
      filterText(val) {
         this.$refs.tree.filter(val);
      }
   },
   data() {
      return {
         currentPageSize: 10,
         dialogTitle: '新增',
         //表格高度
         tableHeight: 0,
         //加载
         tableLoading: false,
         //表格数据
         tableData: [],
         //当前页面
         currentPage: 1,
         //总数
         total: 0,
         //搜索表单
         searchForm: {
            sysRiName: '',
            sysRiStatus: '',
            page: 1,
            pageSize: this.$store.state.initPageSize
         },
         //保存模态框开关
         dialogVisibleSave: false,
         //保存表单
         saveForm: {
            sysRiId: '',
            sysRiName: '',
            sysRiStatus: 0
         },
         //保存表单验证
         saveRules: {
            sysRiName: [
               // {required: true, validator: this.$verification.validateSysRiName, trigger: 'blur'}
               { required: true, message: '请输入角色名称', trigger: 'change' }
            ],
            sysRiStatus: [
               { required: true, message: '请选择角色状态', trigger: 'change' }
            ],
            getSysTiType: [
               { required: true, message: '请选择是否制造商角色', trigger: 'change' }
            ]
         },
         //权限模态框开关
         dialogVisibleGrant: false,
         filterText: '',
         defaultProps: {
            children: 'childList',
            label: 'sysMiName'
         },
         treeData: [],
         grantIdList: [],
         saveGrantForm: {
            roleId: '',
            menuIds: ''
         }
      }
   },
   mounted() {
      //初始化计算表格区域高度
      this.setTableHeight();
      this.searchSubmit()
      this.getGrantTree()

      this.currentPageSize = this.$store.state.initPageSize;
   },
   methods: {
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },
      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
               })();
            };
         })
      },
      /****表格高度计算 end********************************************************************************/
      /****搜素区域 start********************************************************************************/
      //搜索表单提交
      searchSubmit(flag) {
         if (flag) {
            this.currentPage = 1
            this.searchForm.page = 1
            this.searchForm.pageSize = this.currentPageSize
         }
         this.tableLoading = true
         this.$server.getRoleInfo(this.searchForm).then((res) => {
            this.tableLoading = false
            if (res.ok) {
               this.tableData = res.data.records
               this.total = res.data.total
            }
         }).catch(e => {
            this.tableLoading = false
            console.log(e)
         })
      },
      //搜索表单重置
      resetSubmit() {
         this.currentPage = 1
         this.$common.clearFormValue(this.searchForm)
         this.searchForm.page = 1;
         this.searchForm.pageSize = this.currentPageSize;
         this.searchSubmit()
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      //保存表单提交
      saveOpen() {
         this.dialogTitle = '新增'
         this.saveForm = {
            sysRiId: '',
            sysRiName: '',
            sysRiStatus: 0
         }
         this.dialogVisibleSave = true
      },
      //模态框关闭前回调
      handleClose() {
         this.dialogVisibleSave = false
         this.clearForm('saveForm')
      },
      _resetPage() {
         this.currentPage = 1,
            this.searchForm.page = 1
      },
      //保存提交
      saveSubmit(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$server.saveRoleInfo(this.saveForm).then(res => {
                  if (res.ok) {
                     this._resetPage()
                     this.$message.success(res.msg)
                     this.searchSubmit()
                  } else {
                     this.$message.error(res.msg)
                  }
                  this.dialogVisibleSave = false
                  this.clearForm('saveForm')
                  this.$common.clearFormValue(this.saveForm)
               }).catch(e => {
                  console.log(e)
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },
      //清空表单
      clearForm(formName) {
         this.$refs[formName].resetFields();
         if (this.dialogTitle != '新增') {
            this.saveForm.sysRiName = "";
            this.saveForm.sysRiStatus = "";
         } else {
            this.$common.clearFormValue(this.saveForm)
         }
      },
      //编辑模态框开启
      editOpen(row) {
         this.dialogTitle = '编辑'
         this.dialogVisibleSave = true
         this.saveForm = JSON.parse(JSON.stringify(row))
         console.log(this.saveForm)
      },
      updateStatus(row) {
         let data = {
            sysRiId: row.sysRiId,//角色ID
            sysRiStatus: row.sysRiStatus,
            sysRiVersion: row.sysRiVersion
         }
         this.$server.updateStatus(data).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
               this.searchSubmit()
            } else {
               this.$message.error(res.msg)
            }
            this.dialogVisibleSave = false
            this.$common.clearFormValue(this.saveForm)
         }).catch(e => {
            console.log(e)
         })
      },
      /****保存区域 end********************************************************************************/
      /****权限区域 start********************************************************************************/
      grantOpen(row) {
         this.$server.getGrantIdList({ roleId: row.sysRiId }).then(res => {
            if (res.ok) {
               this.saveGrantForm.roleId = row.sysRiId
               this.saveGrantForm.menuIds = []
               this.$nextTick(() => {
                  const that = this
                  res.data.forEach((i, n) => {
                     const node = that.$refs.tree.getNode(i)
                     if (node.isLeaf) {
                        that.$refs.tree.setChecked(node, true)
                     }
                  })
               })
               this.$refs.tree.setCheckedKeys(this.saveGrantForm.menuIds);
            } else {
               this.$message.error(res.msg)
            }
         }).catch(e => {
            console.log(e)
         })
         this.dialogVisibleGrant = true
      },
      grantClose() {
         this.dialogVisibleGrant = false
         this.$common.clearFormValue(this.saveGrantForm)
      },
      saveGrant() {
         let grantIdList = []
         let selectList = this.$refs.tree.getCheckedNodes(false, true)
         selectList.forEach((item, i) => {
            grantIdList.push(item.sysMiId)
         })
         console.log('树返回===', JSON.stringify(grantIdList))
         this.saveGrantForm.menuIds = grantIdList.join(',')
         if (!this.saveGrantForm.menuIds.length) {
            this.$message.error('请至少选择一个权限')
            return
         }
         this.$server.saveGrant(this.saveGrantForm).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
            } else {
               this.$message.error(res.msg)
            }
            this.$refs.tree.setCheckedKeys([]);
            this.$common.clearFormValue(this.saveGrantForm)
            this.dialogVisibleGrant = false
         }).catch(e => {
            console.log(e)
         })
      },
      filterNode(value, data) {
         if (!value) return true;
         return data.sysMiName.indexOf(value) !== -1;
      },
      /****权限区域 end********************************************************************************/
      /****删除区域 start********************************************************************************/
      delOpen(row) {
         this.$confirm('确认删除该条数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            this.$server.removeRoleInfo({ id: row.sysRiId }).then(res => {
               if (res.ok) {
                  this.$message.success(res.msg)
                  this.searchSubmit()
               } else {
                  this.$message.error(res.msg)
               }
            }).catch(e => {
               console.log(e)
            })
         }).catch(() => {
         });
      },
      /****删除区域 end********************************************************************************/
      handleSizeChange(val) {
         this.currentPage = 1
         this.currentPageSize = val
         this.searchForm.pageSize = val
         this.searchForm.page = 1
         this.searchSubmit()
      },
      handleCurrentChange(val) {
         this.currentPage = val
         this.searchForm.page = val
         this.searchSubmit()
      },
      //排序
      sortChange(row) {
         console.log('排序排序排序', JSON.stringify(row))
         this.searchForm.orderByColumn = row.prop
         if (row.order == 'ascending') {
            this.searchForm.order = 'asc'
         } else if (row.order == 'descending') {
            this.searchForm.order = 'desc'

         } else {
            this.searchForm.order = ''
         }
         this.searchForm.page = 1;
         this.searchSubmit()
      },
      getGrantTree() {
         this.$server.getGrantTree().then(res => {
            if (res.ok) {
               this.treeData = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      onSubmit() {
         console.log('submit!');
      }
   }

}
</script>

<style lang="scss">
.role {
   .search {
      //搜索展开收起按钮位置设置
      .searchOpenBtn {
         float: right;
      }

      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }
   }
}
</style>

<style scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}
</style>
